const config = {
    //domain: 'http://127.0.0.1:8000/api/mycrm',
    // domain: 'https://apiv2.loanmarket.co.id/api/mycrm',
    // urlImage: "https://apiv2.loanmarket.co.id/",
    // domain: 'http://ec2-108-136-243-53.ap-southeast-3.compute.amazonaws.com/apimycrm/api/mycrm',
    urlImage: process.env.REACT_APP_BASE_URL_IMAGE,
    domain: process.env.REACT_APP_BASE_URL_DOMAIN,
    //domainAuth : 'http://localhost:3001/codeid/auth/',
    ticketing: 'https://apiv2.loanmarket.co.id/s-ticket/api',
}

export default config