import axios from 'axios'
// import API from './RestAPI.json'
import API from './RestAPI.js'
import cookie from 'react-cookies'
import URL from './RestAPI'
import FileSaver from 'file-saver'
import { message } from 'antd'


if (cookie.load('dataUser') !== undefined) {
  const Token = cookie.load('userToken');
  var config = {
    headers: { Authorization: `Bearer ${Token}`, "Content-Type": "multipart/form-data" }
  };
} else {
  const Token = null
  var config = {
    headers: { Authorization: `Bearer ${Token}` }
  };
}

export const LoginUser = async (data) => {
  return await axios.post(API.Login, data
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const LoginUserRevisiOTP = async (data) => {
  return await axios.post(API.LoginRevisiOTP, data
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const LoginVerifyOTP = async (data) => {
  return await axios.post(API.LoginVerifyOTP, data
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const RefreshToken = async () => {
  const refreshToken = cookie.load('refreshToken');
  // var config = {
  //   headers: { Authorization: `Bearer ${refreshToken}`}
  // };
  return await axios.post(API.RefreshToken, null, {
    headers: { "Authorization": `Bearer ${refreshToken}`},
  }
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const LoginGetDataUser = async (token) => {
  var config = {
    headers: { Authorization: `Bearer ${token}`}
  };
  return await axios.get(API.LoginGetDataUser, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ImportBankProductApi = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return await axios.post(API.ImportBankProductApi, formData, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ExportBankProductApi = async () => {
  const Token = cookie.load('userToken');
    return await axios({
      url: API.ExportBankProductApi,
      method: 'GET',
      responseType: 'blob', 
      headers: { Authorization : `Bearer ${Token}`}
    }).then((response) => {
       FileSaver(response.data,'BANK PRODUCT '+ new Date().toISOString().split('T')[0] + '.xlsx') 
    });
}

export const ExportReportCSVPefindo = async (from,to) => {
    const Token = cookie.load('userToken');
    return await axios({
      url: API.ExportReportCSVPefindo+`?from=${from}&to=${to}`,
      method: 'GET',
      responseType: 'blob', 
      headers: { Authorization : `Bearer ${Token}`}
    }).then((response) => {
       FileSaver(response.data,'log'+ new Date().toISOString().split('T')[0] + '.zip') 
       return true
    }).catch( e => {
      return false
    });
}

export const DownloadDocument = async (folder_id, file_name, doc_name) => {
  const Token = cookie.load('userToken');
  return await axios({
    url: API.DownloadDocument + folder_id + "/" + file_name + "?doc_name=" + doc_name,
    method: 'get',
    responseType: 'blob', 
    headers: { Authorization : `Bearer ${Token}`}
  }).then((response) => {
    FileSaver.saveAs(response.data, doc_name);
  });
};

export const GetProfil = async () => {
  return await axios.get(API.GetProfil, config
  )
    .then(response => { return { "data": response.data.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteDocs = async (id) => {
  return await axios.post(API.DeleteDocs + id, [], config
  )
    .then(response => { return { "data": response.data.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteBankProduct = async (id) => {
  return await axios.delete(API.DeleteBankProduct + id, config
  )
    .then(response => { return { "data": response.data.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const GetOffice = async () => {
  return await axios.get(API.GetOffice, config
  )
    .then(response => { return { "data": response.data.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const GetFilterBankDetail = async (id) => {
  return await axios.get(API.FilterDetailBank + id, config
  )
    .then(response => { return { "data": response.data.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const CPA = async (id, form) => {
  return await axios.post(API.CPA + id, form, config
    // export const CPA = async (form) => {
    //   return await axios.post(API.CPA, form, config
  )
    .then(response => { return { "data": response.data.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateProfil = async (data) => {
  return await axios.post(API.UpdateProfil, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const GetProductBank = async (id, search, order) => {
  return await axios.get(API.GetProductBank + id + "?search=" + search + "&order=" + order, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const GetDetailProduk = async (id) => {
  return await axios.get(API.GetDetailProduk + id + "/edit", config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateProduct = async (id, data) => {
  return await axios.post(API.UpdateProduk + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateBank = async (id, data) => {
  return await axios.post(API.UpdateBank + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailBank = async (id) => {
  return await axios.get(API.DetailBank + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListBank = async (order) => {
  return await axios.get(API.ListBank + '?order=' + order, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const SearchBank = async (order, value) => {
  return await axios.get(API.ListBank + "?search=" + value + "&order=" + order, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const AddProduct = async (data) => {
  return await axios.post(API.AddProduct, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListProduct = async () => {
  return await axios.get(API.ListProduct, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const AddBank = async (data) => {
  return await axios.post(API.AddBank, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteBank = async (id) => {
  return await axios.delete(API.DeleteBank + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateCP = async (data) => {
  return await axios.post(API.CreateCP, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateAdminBank = async (data) => {
  return await axios.post(API.CreateAdminBank, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateBO = async (data) => {
  return await axios.post(API.CreateBO, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateBankOfficer = async (id, data) => {
  return await axios.post(API.UpdateBO + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteBankOfficer = async (id) => {
  return await axios.delete(API.UpdateBO + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteAdminBank = async (id) => {
  return await axios.delete(API.DeleteAdminBank + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteCp = async (id) => {
  return await axios.delete(API.DeleteCp + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteCabang = async (id) => {
  return await axios.delete(API.DeleteCabang + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const BankBranchByBank = async (id, search, idBranch, idSort) => {
  return await axios.get(API.BankBranchByBank + id + "?city=" + idBranch + "&sort=" + idSort + "&search=" + search, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateAdminBank = async (id, form) => {
  return await axios.post(API.updateAdminBank + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailBankAdmin = async (id) => {
  return await axios.get(API.DetailAdminBank + id + "/edit", config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const AdminBankByBank = async (id) => {
  return await axios.get(API.AdminBankByBank + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListProvince = async () => {
  return await axios.get(API.ListProvince, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListCityByProvince = async (id) => {
  return await axios.get(API.ListCityByProvince + id, config)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateCabangBank = async (data) => {
  return await axios.post(API.CreateCabangBank, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const EditCabangBank = async (id, data) => {
  return await axios.post(API.EditCabangBank + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListCpByBranch = async (id) => {
  return await axios.get(API.ListCpByBranch + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailCp = async (id) => {
  return await axios.get(API.DetailCp + id + "/edit", config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailCabangBank = async (id) => {
  return await axios.get(API.DetailCabangBank + id + "/edit", config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateCp = async (id, data) => {
  return await axios.post(API.UpdateCp + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListBoByBranch = async (id) => {
  return await axios.get(API.ListBoByBranch + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailBo = async (id) => {
  return await axios.get(API.DetailBo + id + "/edit", config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListContact = async () => {
  return await axios.get(API.ListContact, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListContactByAdviser = async (id, value, row, page, category = "") => {
  return await axios.get(API.ListContactByAdviser + id + "?search=" + value + "&row=" + row + "&page=" + page + "&category=" + category, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListContactOtherByAdviser = async (id, value, row, page) => {
  return await axios.get(API.ListContactOtherByAdviser + id + "?search=" + value + "&row=" + row + "&page=" + page, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListContactActive = async (value, row, page) => {
  return await axios.get(API.ListContactActive + "?search=" + value + "&row=" + row + "&page=" + page, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListContactDeactive = async (value, row, page) => {
  return await axios.get(API.ListContactDeactive + "?search=" + value + "&row=" + row + "&page=" + page, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateProductDeveloper = async (form) => {
  return await axios.post(API.ProductDeveloper, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ProductDeveloper = async () => {
  return await axios.get(API.ProductDeveloper, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteProductDeveloper = async (id) => {
  return await axios.delete(API.DeleteProductDeveloper + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailProductDeveloper = async (id) => {
  return await axios.get(API.ProductDeveloper + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const EditProductDeveloper = async (id) => {
  return await axios.get(API.ProductDeveloper + "/edit/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const updateProductDeveloper = async (id,form) => {
  return await axios.post(API.ProductDeveloper + "/update/" + id , form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const LoanByDeveloper = async (id, source, search) => {
  return await axios.get(API.LoanByDeveloper + id + "?source=" + source + "&search=" + search, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListProductByDev = async (id, product) => {
  return await axios.get(API.ListProductByDev + id + "&product=" + product, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const updateProject = async (form) => {
  return await axios.post(API.updateProject + form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateProjectDev = async (form) => {
  return await axios.post(API.CreateProjectDev, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteProject = async (id) => {
  return await axios.delete(API.DeleteProject + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListProjectByDeveloper = async (id) => {
  return await axios.get(API.ListProjectByDeveloper + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListProjectByDevNew = async (idDeveloper) => {
  return await axios.get(API.ListProjectByDeveloperNew + "?developer_id=" + idDeveloper, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListPropertyByProjectNew = async (idProject) => {
  return await axios.get(API.ListPropertyByProjectNew + "?project_id=" + idProject, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListProductKPR = async () => {
  return await axios.get(API.ListProductKPR, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListsDeveloper = async () => {
  return await axios.get(API.ListsDeveloper, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListsDeveloperNew = async () => {
  return await axios.get(API.ListsDeveloperNew, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListContactByOffice = async (id, value, row, page) => {
  return await axios.get(API.ListContactByOffice + id + "?search=" + value + "&row=" + row + "&page=" + page, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListContactPartnerByAdmin = async (id, search) => {
  return await axios.get(API.ListContactPartner + id + "?search=" + search, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CategoryPartner = async () => {
  return await axios.get(API.CategoryPartner, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListContactPartner = async () => {
  return await axios.get(API.ContactPartner, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const SearchContactPartner = async (value, month, year) => {
  return await axios.get(API.ContactPartner + "?search=" + value + "&month=" + month + "&year=" + year, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailContact = async (id) => {
  return await axios.get(API.DetailContact + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailContactPartner = async (id) => {
  return await axios.get(API.ContactPartner + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListNoteByContact = async (id) => {
  return await axios.get(API.ListNoteByContact + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListAppByContact = async (id) => {
  return await axios.get(API.ListAppByContact + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateContact = async (id, data) => {
  return await axios.post(API.UpdateContact + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const postAdviserContact = async (id, form) => {
  return await axios.post(API.postAdviserContact + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateContactPartner = async (id, data) => {
  return await axios.post(API.ContactPartner + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteContact = async (id) => {
  return await axios.delete(API.DeleteContact + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteContactPartner = async (id) => {
  return await axios.delete(API.ContactPartner + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const RestoreContact = async (id) => {
  return await axios.post(API.RestoreContact + id, [], config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListAccessContact = async (id, value) => {
  return await axios.get(API.ListAccessContact + id + "?search=" + value, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UnAccessContact = async (id, value) => {
  return await axios.get(API.UnAccessContact + id + "?search=" + value, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteAccess = async (id) => {
  return await axios.delete(API.DeleteAccess + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const SendToAdviser = async (id, form) => {
  return await axios.post(API.SendToAdviser + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateAccess = async (form) => {
  return await axios.post(API.CreateAccess, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "status": false } })
}
export const DetailNote = async (id) => {
  return await axios.get(API.DetailNote + id + "/edit", config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "status": false } })
}
export const CreateNote = async (form) => {
  return await axios.post(API.CreateNote, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "status": false } })
}
export const DeleteNote = async (id) => {
  return await axios.delete(API.DeleteNote + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "status": false } })
}
export const UpdateNote = async (id, form) => {
  return await axios.post(API.UpdateNote + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "status": false } })
}
export const CreateContact = async (form) => {
  return await axios.post(API.CreateContact, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const CreateContactPartner = async (form) => {
  return await axios.post(API.ContactPartner, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListAppByPrincipal = async (id, source, value, row, page, bank, product) => {
  return await axios.get(API.ListAppByPrincipal + id + "?source=" + source + "&search=" + value + "&row=" + row + "&page=" + page + "&bank=" + bank + "&product=" + product, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const SearchListAppByPrincipal = async (id, form) => {
  return await axios.post(API.ListAppByPrincipal + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListAppByAdviser = async (id, source, value, row, page, bank, product, status) => {
  return await axios.get(API.ListAppByAdviser + id + "?source=" + source + "&search=" + value + "&row=" + row + "&page=" + page + "&bank=" + bank + "&product=" + product + "&status=" + status, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const SearchListAppByAdviser = async (id, form) => {
  return await axios.post(API.ListAppByAdviser + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListAdviserByOffice = async (id, value, row, page, sortBy = '') => {
  if (sortBy) {
    return await axios.get(API.ListAdviserByOfficeAdmin + id + "?search=" + value + "&row=" + row + "&page=" + page + "&sort=" + sortBy, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
  } else {
    return await axios.get(API.ListAdviserByOfficeAdmin + id + "?search=" + value + "&row=" + row + "&page=" + page, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
  }
  
}
export const ListAdviserByOfficeAdmin = async (id, value, row, page, sortBy = '') => {
  if (sortBy) {
    return await axios.get(API.ListAdviserByOfficeAdmin + id + "?search=" + value + "&row=" + row + "&page=" + page + "&sort=" + sortBy, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
  } else {
    return await axios.get(API.ListAdviserByOfficeAdmin + id + "?search=" + value + "&row=" + row + "&page=" + page, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
  }
  
}

export const ListAdviserByCsm = async (id, value, row, page) => {
  return await axios.get(API.ListAdviserByCsm + id + "?search=" + value + "&row=" + row + "&page=" + page, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListAdviser = async () => {
  return await axios.get(API.ListAdviser, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailAdviser = async (id) => {
  return await axios.get(API.ListAdviser + "/" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListAppByBO = async (id, source, value, product, month) => {
  return await axios.get(API.ListAppByBO + id + "?source=" + source + "&search=" + value + "&product=" + product + "&month=" + month, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListAppByAdmin = async (source, value, row, page, product, bank) => {
  return await axios.get(API.ListAppByAdmin + "?source=" + source + "&search=" + value + "&row=" + row + "&page=" + page + "&product=" + product + "&bank=" + bank, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteProduct = async (id) => {
  return await axios.delete(API.DeleteProduct + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const Notification = async (id, group, Row) => {
  return await axios.get(API.Notification + id + "?group=" + group + "&row=" + Row, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteNotification = async (id) => {
  return await axios.delete(API.DeleteNotification + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteNotificationAll = async (id, form) => {
  return await axios.post(API.DeleteNotificationAll + id + "/all", form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ForgetPassword = async (data) => {
  return await axios.post(API.ForgetPassword, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ResetPassword = async (data) => {
  return await axios.post(API.ResetPassword, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListOffice = async () => {
  return await axios.get(API.ListOfficeForAdmin, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListOfficeForAdmin = async (value, row, page) => {
  return await axios.get(API.ListOfficeForAdmin + "?row=" + row + "&search=" + value + "&page=" + page, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailApp = async (id) => {
  return await axios.get(API.DetailApp + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ProcessToBank = async (id) => {
  return await axios.post(API.ProcessToBank + id, [], config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ProcessToOneBank = async (id, form) => {
  return await axios.post(API.ProcessToOneBank + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailAppEdit = async (id) => {
  return await axios.get(API.DetailApp + id + "/edit", config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListCabangBankByBank = async (id, value, row, page) => {
  return await axios.get(API.ListCabangBankByBank + id + "?row=" + row + "&search=" + value + "&page=" + page, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const SelectCabangBankByBank = async (id) => {
  return await axios.get(API.ListCabangBankByBank + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const Reference = async () => {
  return await axios.get(API.Reference, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListProv = async () => {
  return await axios.get(API.ListProv, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListAppByProduct = async (id) => {
  return await axios.get(API.ListAppByProduct + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListAppByOffice = async (id) => {
  return await axios.get(API.ListAppByOffice + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteApp = async (id) => {
  return await axios.delete(API.DeleteApp + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailAdviserManagement = async (id) => {
  return await axios.get(API.DetailAdviserManagement + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const AdvisorByOfficeOnAdd = async (id, Role) => {
  return await axios.get(API.AdvisorByOfficeOnAdd + "?role=" + Role + "&office=" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const CreateUser = async (form) => {
  return await axios.post(API.CreateUser, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListRole = async () => {
  return await axios.get(API.ListRole, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateAdviser = async (id, form) => {
  return await axios.post(API.DetailAdviserManagement + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const Active = async (id) => {
  return await axios.post(API.Active + id, [], config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const Deactive = async (id) => {
  return await axios.post(API.Deactive + id, [], config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const AdvisorByOffice = async (id, group, search, page, row) => {
  return await axios.get(API.AdvisorByOffice + "?role=" + group + "&search=" + search + "&id_office=" + id + '&page=' + page + '&row=' + row, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UserManagementList = async (group, search, page, row) => {
  return await axios.get(API.UserManagement + "?role=" + group + "&search=" + search + '&page=' + page + '&row=' + row, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const GetMenuDashboard = async (id, group) => {
  return await axios.get(API.GetMenuDashboard + "?group=" + group + "&id=" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateApp = async (form) => {
  return await axios.post(API.CreateApp, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const SetTarget = async (id, form) => {
  return await axios.post(API.SetTarget + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const Logout = async () => {
  return await axios.post(API.Logout, [], config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ChangePassword = async (form) => {
  return await axios.post(API.ChangePassword, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateApp = async (id, form) => {
  return await axios.post(API.UpdateApp + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const RejectApp = async (id) => {
  return await axios.post(API.RejectApp + id, [], config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailProspectBank = async (id) => {
  return await axios.get(API.DetailProspectBank + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListPoolByBO = async (product) => {
  return await axios.get(API.ListPoolByBO + '?product=' + product, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const PdfCPAPool = async (id) => {
  return await axios.post(API.PdfCPAPool + id, [], config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const RequestData = async (id) => {
  return await axios.post(API.RequestData + id, [], config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ApproveApp = async (id) => {
  return await axios.post(API.ApproveApp + id, [], config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const EmailOneBO = async (form) => {
  return await axios.post(API.EmailOneBO, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const AccessToClient = async (id) => {
  return await axios.post(API.AccessToClient + id, [], config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListStepApp = async (id, idBank) => {
  return await axios.get(API.ListStepApp + id + "?id_bank_officer=" + idBank, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailStepLoan = async (id) => {
  return await axios.get(API.DetailStepLoan + id + "/edit", config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const GetStatusSelect = async (id, idBo, idBank) => {
  return await axios.get(API.GetStatusSelect + id + "?id_bank_officer=" + idBo + "&id_bank=" + idBank, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateStepLoan = async (id, form) => {
  return await axios.post(API.UpdateStepLoan + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const Download = async (url) => {
  return await axios.get(url,
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'image/png, image.jpeg, application/pdf',
      }
    })
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateStepLoan = async (form) => {
  return await axios.post(API.CreateStepLoan, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const GetWilayahByBank = async (id) => {
  return await axios.get(API.GetWilayahByBank + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListContactType = async () => {
  return await axios.get(API.ListContactType, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListJobType = async () => {
  return await axios.get(API.ListJobType, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListCategory = async () => {
  return await axios.get(API.ListCategory, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListCategoryById = async (id) => {
  return await axios.get(API.ListCategory + '?adviser_id=' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListBranchByCityAndBank = async (id, idBank) => {
  return await axios.get(API.ListBranchByCityAndBank + "city=" + id + "&bank=" + idBank, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CityByProvince = async (id) => {
  if (id >= 1) {
    return await axios.get(API.CityByProvince + id, config
    )
      .then(response => { return { "data": response.data, "status": true } })
      .catch((error) => { return { "data": error.response, "status": false } })
  } else {
    return { "status": false }
  }
}
export const DistrictByCity = async (id) => {
  if (id >= 1) {
    return await axios.get(API.DistrictByCity + id, config
    )
      .then(response => { return { "data": response.data, "status": true } })
      .catch((error) => { return { "data": error.response, "status": false } })
  } else {
    return { "status": false }
  }
}
export const SuburbByDistrict = async (id) => {
  if (id >= 1) {
    return await axios.get(API.SuburbByDistrict + id, config
    )
      .then(response => { return { "data": response.data, "status": true } })
      .catch((error) => { return { "data": error.response, "status": false } })
  } else {
    return { "status": false }
  }
}
export const ListProductById = async (id, IdApp, search, order) => {
  return await axios.get(API.ListProductById + id + "?search=" + search + "&order=" + order + "&app=" + IdApp, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListBOByBranch = async (id, Page, Row, Search) => {
  return await axios.get(API.ListBOByBranch + id + '?page=' + Page + '&row=' + Row + '&search=' + Search, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const PostTest = async (id) => {
  return await axios.get(API.PostTest + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListLoanByPartner = async (id, search) => {
  return await axios.get(API.ListLoanByPartner + id + "?search=" + search, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const SearchCreditScoreCompany = async (form) => {
  return await axios.post(API.SearchCreditScoreCompany, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const SearchCreditScoreIndividual = async (form) => {
  return await axios.post(API.SearchCreditScoreIndividual, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const AddContactAll = async (form) => {
  return await axios.post(API.AddContactAll, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ReportCreditScore = async (form) => {
  return await axios.post(API.ReportCreditScore, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DetailCreditScoreDB = async (form) => {
  return await axios.post(API.DetailCreditScoreDB, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateInvoice = async (form) => {
  return await axios.post(API.CreateInvoice, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListInvoice = async (id) => {
  return await axios.get(API.ListInvoice + "office=" + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const PdfReport = async (form) => {
  return await axios.post(API.PdfReport, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const PdfCreditScoreDB = async (form) => {
  return await axios.post(API.PdfCreditScoreDB, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateInvoice = async (id, form) => {
  return await axios.post(API.UpdateInvoice + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const HistoryCreditScore = async (from, to, type, office) => {
  return await axios.get(API.HistoryCreditScore + "from_date=" + from + "&to_date=" + to + "&type=" + type + "&office=" + office, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListSubscriber = async () => {
  return await axios.get(API.ListSubscriber, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateSubscriber = async (id, form) => {
  return await axios.post(API.UpdateSubscriber + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ContactByAdviser = async (id, search, type) => {
  return await axios.get(API.ContactByAdviser + id + "?search=" + search + "&type=" + type, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const RequestCreditScore = async (form) => {
  return await axios.post(API.RequestCreditScore, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListRequest = async (IdOffice, IdAdviser) => {
  return await axios.get(API.ListRequest + "office=" + IdOffice + "&adviser=" + IdAdviser, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListHistoryByAdmin = async (IdOffice, from, to, status, type, type_request) => {
  return await axios.get(API.ListHistoryByAdmin + "office=" + IdOffice + "&from_date=" + from + "&to_date=" + to + "&status=" + status + "&type=" + type + "&type_request=" + type_request, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteRequest = async (Id) => {
  return await axios.delete(API.DeleteRequest + Id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListRequestByOffice = async (IdOffice, IdAdviser, Status) => {
  return await axios.get(API.ListRequestByOffice + "office=" + IdOffice + "&adviser=" + IdAdviser + '&status=' + Status, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const StatusAdminOfficer = async (id, req) => {
  return await axios.get(API.ListStatbyAdminOff + id + '?source=' + req, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "status": false } })
}
export const ListAppByAO = async (id, source, product, bank) => {
  return await axios.get(API.ListStatbyAdminOff + id + "?source=" + source + "&product=" + product + "&bank=" + bank, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const ListFaq = async () => {
  return await axios.get(API.ListFaq, config)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const FaqSearch = async (search) => {
  return await axios.get(API.ListFaq + '?search=' + search, config)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const CreateFaq = async (form) => {
  return await axios.post(API.ListFaq, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const FaqEdit = async (id) => {
  return await axios.get(API.ListFaq + '/' + id + '/edit', config)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const UpdateFaq = async (id, form) => {
  return await axios.post(API.ListFaq + '/' + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const DeleteFaq = async (id) => {
  return await axios.delete(API.ListFaq + '/' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListPipeline = async () => {
  return await axios.get(API.ListPipeline, config)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const UpdatePresentage = async (id, form) => {
  return await axios.post(API.UpdatePresetase + '/' + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}


export const CreateReferral = async (form) => {
  return await axios.post(API.Referral, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const UpdateReferral = async (id, form) => {
  return await axios.post(API.Referral + '/' + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}


export const DeleteReferral = async (id) => {
  return await axios.delete(API.Referral + '/' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}


export const CreateTransaksi = async (form) => {
  return await axios.post(API.Transaksi + '/postTransaksi', form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListTransaksi = async () => {
  return await axios.get(API.Transaksi+'/get', config)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const UpdateTransaksi = async (id, form) => {
  return await axios.post(API.Transaksi + '/update/' + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const TransaksiInvoice = async (form) => {
  return await axios.post(API.Transaksi + '/invoice', form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const TransaksiReferral = async (id) => {
  return await axios.get(API.Transaksi + '/referral/'+id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const LmsRekening = async () => {
  return await axios.post(API.LmsRekening,  config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const CreateRekening = async (form) => {
  return await axios.post(API.LmsRekening + '/post', form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}


export const UpdateRekening = async (id, form) => {
  return await axios.post(API.LmsRekening + '/update/' + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteRekening = async (id) => {
  return await axios.get(API.LmsRekening + '/' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const statusPipeline = async (id, form) => {
  return await axios.post(API.CreateApp +'/change-pipeline-status/'+ id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}


export const SendInvoice = async (form) => {
  return await axios.post(API.Transaksi +'/sendInvoice', form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}


export const ListInvoiceLMS = async () => {
  return await axios.get(API.InvoiceLMS+'/get', config)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetVocher = async (form) => {
  return await axios.post(API.InvoiceLMS+'/get-vocher',form, config)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}


export const ListTimeline = async (id) => {
  return await axios.get(API.InvoiceLMS+'/get-log/'+id, config)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const UpdateInvoiceLMS = async (form) => {
  return await axios.post(API.InvoiceLMS + '/change-status', form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListContactByWebsite = async (search) => {
  return await axios.get(API.ListContactWebsite + "?search=" + search, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const TopFiveBankInterestApi = async (id) => {
  return await axios.get(API.TopFiveBank+id, config
    )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const TopFiveBankApproveApi = async () => {
  return await axios.get(API.TopFiveBankApprove, config
    )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListPostalCode = async (data1,data2) => {
  return await axios.get(API.ListPostalCodeApi + "?district=" + data1 + "&sub_district=" + data2, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProductCategoryParent = async () => {
  return await axios.get(API.ProductCategoryParentApi, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProductCategoryParentPost = async (form) => {
  return await axios.post(API.ProductCategoryParentApi, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProductCategoryParentDelete = async (id) => {
  return await axios.delete(API.ProductCategoryParentApi+"/"+id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProductCategoryParentUpdate = async (id, form) => {
  return await axios.post(API.ProductCategoryParentApi+"/"+id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProductGetCategoryParent = async () => {
  return await axios.get(API.ProductGetCategoryParent, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProductGetCategory = async () => {
  return await axios.get(API.ProductGetCategory, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProductGetType = async () => {
  return await axios.get(API.ProductGetType, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListProductDelete = async (id) => {
  return await axios.delete(API.ListProduct+"/"+id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListProductPost = async (form) => {
  return await axios.post(API.ListProduct, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListProductEdit = async (form, id) => {
  return await axios.post(API.ListProduct+"/"+id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListContactByWebsitePost = async (form, id) => {
  return await axios.post(API.ListContactWebsite + "/" + id, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListOfficeV2 = async () => {
  return await axios.get(API.ListOffice, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListBankNonPks = async (order) => {
  return await axios.get(API.ListBankNonPks + '?order=' + order, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const AddBankNonPks = async (data) => {
  return await axios.post(API.AddBankNonPks, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const SearchBankNonPks = async (order, value) => {
  return await axios.get(API.ListBankNonPks + "?search=" + value + "&order=" + order, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetProductBankNonPks = async (id, search, order) => {
  return await axios.get(API.GetProductBankNonPks + id + "?search=" + search + "&order=" + order, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const AddProductNonPks = async (data) => {
  return await axios.post(API.AddProductNonPks, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteProductNonPks = async (id) => {
  return await axios.delete(API.DeleteProductNonPks + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const UpdateProductNonPks = async (id, data) => {
  return await axios.post(API.UpdateProdukNonPks + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListProductNonPksById = async (id, IdApp, search, order) => {
  return await axios.get(API.ListProductNonPksById + id + "?search=" + search + "&order=" + order + "&app=" + IdApp, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const BankReview = async (data) => {
  return await axios.post(API.BankReview, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetReviewAPI = async (id) => {
  return await axios.get(API.GetReview + id, config)
    .then((response) => { return { data: response.data, status: true } })
    .catch((error) => { return { data: error.response, status: false } })
}

export const DeleteReview = async (id) => {
  return await axios.delete(API.DeleteReview + id, config)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const TopBankOfMonth = async (monthAgo) => {
  return await axios.get(API.TopBankOfMonth + "?monthAgo=" + monthAgo, config
    )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const TopBankOfResponse = async (weekAgo) => {
  return await axios.get(API.TopBankOfResponse + "?weekAgo=" + weekAgo, config
    )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetBusinessType = async () => {
  return await axios.get(API.BusinessType, config
    )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ExportBankOfficerApi = async () => {
  const Token = cookie.load('userToken');
    return await axios({
      url: API.ExportBankOfficerApi,
      method: 'GET',
      responseType: 'blob', 
      headers: { Authorization : `Bearer ${Token}`}
    }).then((response) => {
       FileSaver(response.data,'BANK OFFICER '+ new Date().toISOString().split('T')[0] + '.xlsx') 
    });
}

export const ImportBankOfficerApi = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  return await axios.post(API.ImportBankOfficerApi, formData, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })

}

export const DownloadDocs = async (id) => {
  const Token = cookie.load('userToken');
    return await axios({
      url: API.DownloadDocs+id,
      method: 'POST',
      responseType: 'blob', 
      headers: { Authorization : `Bearer ${Token}`}
    }).then((response) => {
       FileSaver(response.data,`Application-Documents.zip`) 
    });
}

export const TransferContact = async (form) => {
  if (cookie.load('dataUser') !== undefined) {
    const Token = cookie.load('userToken');
    var configTransferContact = {
      headers: { Authorization: `Bearer ${Token}`, 'Content-Type': 'application/json'}
    };
  } else {
    const Token = null
    var configTransferContact = {
      headers: { Authorization: `Bearer ${Token}`,"Content-Type": "application/json"}
    };
  }
  return await axios.put(API.TransferContact, form, configTransferContact)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ListContactTransfer = async (id) => {
  return await axios.get(API.ListContactTransfer + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DownloadFile = async (contact_id, file_name, ext, name) => {
    const Token = cookie.load('userToken');
    return await axios({
      url: API.DownloadFile + contact_id + '/' + file_name,
      method: 'get',
      responseType: 'blob', 
      headers: { Authorization : `Bearer ${Token}`}
    }).then((response) => {
       FileSaver(response.data,`${name}.${ext}`) 
    });
};

export const ListContactCategory = async (idAdviser) => {
  if (idAdviser) {
    return await axios.get(API.ContactCategory + '?adviser_id=' + idAdviser, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
  } else {
    return await axios.get(API.ContactCategory, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
  }
}

export const PostContactCategory = async (data) => {
  return await axios.post(API.ContactCategory, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const EditContactCategory = async (id, data) => {
  return await axios.post(API.ContactCategory + '/' + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteContactCategory = async (id) => {
  return await axios.delete(API.ContactCategory + '/' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetBusinessCard = async () => {
  return await axios.get(API.MarketingTool + '/office', config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const PostDataTemplateNewsletter = async (data) => {
  return await axios.post(API.MarketingTool + '/template', data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const EditDataTemplateNewsletter = async (id, data) => {
  return await axios.post(API.MarketingTool + '/template/' + id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetAllSavedTemplate = async () => {
  return await axios.get(API.MarketingTool + '/template', config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetAllSavedTemplateFeed = async () => {
  return await axios.get(API.MarketingTool + '/template-feed', config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteTemplate = async (id) => {
  return await axios.delete(API.MarketingTool + '/template/' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetTemplate = async (id) => {
  return await axios.get(API.MarketingTool + '/template/' + id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const TestEmailNewsletter = async (data) => {
  const Token = cookie.load('userToken');
  var config = {
    headers: { Authorization: `Bearer ${Token}`, "Accept": "application/json" }
  };
  return await axios.post(API.MarketingTool + '/test-email', data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const SendEmailNewsletterContactCategory = async (data) => {
  const Token = cookie.load('userToken');
  var config = {
    headers: { Authorization: `Bearer ${Token}`, "Accept": "application/json" }
  };
  return await axios.post(API.MarketingTool + '/campaign', data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DownlaodKtpNpwp = async (Data) => {
  try {
    const Token = cookie.load('userToken');
    const ktpNpwp = await axios({
      url: API.DownloadKtpNpwp + Data.id,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization : `Bearer ${Token}`}
    });
    const url = window.URL.createObjectURL(ktpNpwp.data);
    const newTab = window.open(url, '_blank');
    return newTab.focus();
  } catch (error) {
    message.error('Gagal memuat file!');
  }
}

export const GetTargetContact = async () => {
  return await axios.get(API.MarketingTool + '/target-contact', config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const UploadPhotoMarketingTools = async (data) => {
  return await axios.post(API.MarketingTool + '/template-upload-file', data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const SelectAdviserPefindo = async () => {
  return await axios.get(API.SelectAdviserPefindo, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const getBankProductPreferencesApi = async () => {
  return await axios.get(API.GetBankProductPreferences, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const getDownloadImagesMarketingTools = async (id) => {
  const Token = cookie.load('userToken');;
  return await axios({
    url: API.MarketingTool + `/template-download-file/${id}`,
    method: 'GET',
    responseType: 'blob', 
    headers: { Authorization : `Bearer ${Token}`}
  }).then((response) => {
     FileSaver(response.data, 'template-instagram.zip');
     return true;
  }).catch( e => {
    return false;
  });
}


// Revisi API for ISO 2024
export const getAplicationDocs = async (appId, fileName) => {
  try {
    const Token = cookie.load('userToken');
    const docs = await axios({
      url: API.AplicationDocs + `/${appId}/docs/${fileName}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization : `Bearer ${Token}`}
    });
    const url = window.URL.createObjectURL(docs.data);
    return url;
  } catch (error) {
    message.error('Gagal memuat file!');
  }
}

export const aplicationDeleteDocs = async (appId, fileName) => {
  const Token = cookie.load('userToken');
  return await axios({
    url: API.AplicationDocs + `/${appId}/docs/${fileName}`,
    method: 'DELETE',
    headers: { Authorization : `Bearer ${Token}`}}
  )
    .then(response => { return { "data": response.data.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const downloadAplicationDocs = async (appId, fileName, downloadDocName) => {
  try {
    const Token = cookie.load('userToken');
    const docs = await axios({
      url: API.AplicationDocs + `/${appId}/docs/${fileName}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization : `Bearer ${Token}`}
    });
    FileSaver(docs.data, downloadDocName);
    return true;
  } catch (error) {
    message.error('Gagal download file!');
  }
}

export const downloadAplicationDocsZip = async (appId) => {
  try {
    const Token = cookie.load('userToken');
    const zip = await axios({
      url: API.AplicationDocs + `/${appId}/download-zip`,
      method: 'POST',
      responseType: 'blob',
      headers: { Authorization : `Bearer ${Token}`}
    });
    FileSaver(zip.data);
    return true;
  } catch (error) {
    message.error('Gagal download file!');
  }
}

export const ProcessToOneBankNew = async (idApplication, id) => {
  return await axios.post(`${API.ProcessToOneBankNew}${idApplication}/send-to-bank/${id}`, null, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProcessToBankNew = async (idApplication) => {
  return await axios.post(`${API.ProcessToOneBankNew}${idApplication}/send-to-bank`, null, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const hitApiUpMaintenance = async () => {
  return await axios.get(API.Maintenance)
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const notificationNew = async () => {
  return await axios.get(API.NotificationNew, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const readOneNotification = async (idNotification) => {
  return await axios.post(`${API.NotificationNew}/${idNotification}`, null, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const readAllNotification = async () => {
  return await axios.post(`${API.NotificationNew}`, null, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetCategoryTicket = async () => {
  return await axios.get(API.Ticket + '/categories', config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetTicket = async (search = '', category = '', status = '', paginationPage = 1) => {
  return await axios.get(API.Ticket + `?search=${search}` + `&category_id=${category}` + `&status=${status}` + `&page=${paginationPage}`, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const createTicket = async (data) => {
  const Token = cookie.load('userToken');
  let config = {
    headers: { Authorization: `Bearer ${Token}`, "Content-Type": "multipart/form-data" }
  };
  return await axios.post(API.Ticket, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetDetailTicket = async (idTicket) => {
  return await axios.get(API.Ticket + `/${idTicket}`, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetPhotoFile = async (idTicket, imgUrl) => {
  try {
    const Token = cookie.load('userToken');
    const image = await axios({
      url: API.Ticket + `/${idTicket}/file/${imgUrl}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization : `Bearer ${Token}`}
    });
    const url = window.URL.createObjectURL(image.data);
    return url;
  } catch (error) {
    message.error('Gagal memuat file!');
  }


  // return await axios.get(API.Ticket + `/${idTicket}/file/${imgUrl}`, config
  // )
  //   .then(response => { return { "data": response.data, "status": true } })
  //   .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetTicketCategory = async () => {
  return await axios.get(API.TicketCategories, null, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const TicketCategoryPost = async (data) => {
  const Token = cookie.load('userToken');
  let config = {
    headers: { Authorization: `Bearer ${Token}`, "Content-Type": "application/json" }
  };
  return await axios.post(API.TicketCategories, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const TicketCategoryUpdate = async (id, data) => {
  const Token = cookie.load('userToken');
  let config = {
    headers: { Authorization: `Bearer ${Token}`, "Content-Type": "application/json" }
  };
  return await axios.put(API.TicketCategories+"/"+id, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const TicketCategoryDelete = async (id) => {
  return await axios.delete(API.TicketCategories+"/"+id, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const replyTicket = async (id, data) => {
  const Token = cookie.load('userToken');
  let config = {
    headers: { Authorization: `Bearer ${Token}`, "Content-Type": "multipart/form-data" }
  };
  return await axios.post(API.Ticket+`/${id}/reply`, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const TicketChangeStatus = async (newData, dataRow) => {
  const Token = cookie.load('userToken');
  let config = {
    headers: { Authorization: `Bearer ${Token}`, "Content-Type": "application/json" }
  };
  return await axios.post(API.Ticket + `/${dataRow.id}/status`, newData, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProductDeveloperNew = async (page = 1, developer_id = 0, project_id = 0) => {
  // return await axios.get(API.ProductDeveloperNew + '?page=1', config
  return await axios.get(API.ProductDeveloperNew + `?page=${page}&developer_id=${developer_id}&project_id=${project_id}`  , config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProjectDeveloperNew = async (page = 0, developer_id = 0) => {
  // return await axios.get(API.ProjectDeveloperNew + '?page=1', config
  return await axios.get(API.ProjectDeveloperNew + `?page=${page}&developer_id=${developer_id}`  , config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const ProjectDeveloperDetail = async (idProject) => {
  // return await axios.get(API.ProjectDeveloperNew + '?page=1', config
  return await axios.get(API.ProjectDeveloperNew + `/${idProject}`  , config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const DeleteProjectDeveloperNew = async (project_id) => {
  // return await axios.get(API.ProjectDeveloperNew + '?page=1', config
  return await axios.delete(API.ProjectDeveloperNew + `/${project_id}`  , config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const CreateProjectDevNew = async (form) => {
  const Token = cookie.load('userToken');
  let config = {
    headers: { Authorization: `Bearer ${Token}`, "Content-Type": "application/json" }
  };
  return await axios.post(API.ProjectDeveloperNew, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const EditProjectDevNew = async (form, id) => {
  const Token = cookie.load('userToken');
  let config = {
    headers: { Authorization: `Bearer ${Token}`, "Content-Type": "application/json" }
  };
  return await axios.put(API.ProjectDeveloperNew + `/${id}`, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const CreateProductDeveloperNew = async (form) => {
  return await axios.post(API.ProductDeveloperNew, form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const FilterDeveloper = async () => {
  return await axios.get(API.FilterDeveloper, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}
export const FilterProject = async (idDeveloper) => {
  return await axios.get(API.FilterProject + `?developer_id=${idDeveloper}`, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const GetProductDeveloperDetail = async (id) => {
  // return await axios.get(API.ProductDeveloperNew + "/edit/" + id, config
  return await axios.get(API.ProductDeveloperNew + `/${id}`, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const updateProductDeveloperNew = async (id,form) => {
  return await axios.post(API.ProductDeveloperNew + '/' + id , form, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const deleteProductDeveloperNew = async (id) => {
  return await axios.delete(API.ProductDeveloperNew + `/${id}`, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const downloadSuratKeteranganTemplate = async (idApplication, id) => {
  try {
    const Token = cookie.load('userToken');
    const file = await axios({
      url: API.AplicationDocs + `/${idApplication}/surat-keterangan-template/${id}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization : `Bearer ${Token}`}
    });
    FileSaver(file.data, 'Surat-Keterangan-Template.pdf');
    return true;
  } catch (error) {
    message.error('Gagal download file!');
  }
}

export const uploadSuratKeteranganSigned = async (idApplication, id, data) => {
  return await axios.post(`${API.AplicationDocs}/${idApplication}/surat-keterangan/${id}`, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const downloadSuratKeteranganSigned = async (idApplication, id) => {
  try {
    const Token = cookie.load('userToken');
    const file = await axios({
      url: API.AplicationDocs + `/${idApplication}/surat-keterangan/${id}`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization : `Bearer ${Token}`}
    });
    FileSaver(file.data, 'Surat-Keterangan.pdf');
    return true;
  } catch (error) {
    message.error('Gagal download file!');
  }
}

export const downloadCPATemplate = async (idApplication) => {
  try {
    const Token = cookie.load('userToken');
    const file = await axios({
      url: API.AplicationDocs + `/${idApplication}/cpa-template`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization : `Bearer ${Token}`}
    });
    FileSaver(file.data, 'CPA-Template.pdf');
    return true;
  } catch (error) {
    message.error('Gagal download file!');
  }
}

export const uploadCPASigned = async (idApplication, data) => {
  return await axios.post(`${API.AplicationDocs}/${idApplication}/cpa`, data, config
  )
    .then(response => { return { "data": response.data, "status": true } })
    .catch((error) => { return { "data": error.response, "status": false } })
}

export const downloadCPASigned = async (idApplication) => {
  try {
    const Token = cookie.load('userToken');
    const file = await axios({
      url: API.AplicationDocs + `/${idApplication}/cpa`,
      method: 'GET',
      responseType: 'blob',
      headers: { Authorization : `Bearer ${Token}`}
    });
    console.log(file)
    FileSaver(file.data, 'CPA.pdf');
    return true;
  } catch (error) {
    message.error('Gagal download file!');
  }
}